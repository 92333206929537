import React from 'react'

export default props => (
  <svg {...props} viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m802.643 1125.188-1.866978 1.83711c-.378332.37179-.888859.57805-1.419272.57342l-4.465911-.03897c-.530412-.00463-1.037262-.21977-1.409047-.5981l-3.130318-3.18544c-.371785-.37833-.578052-.88886-.573423-1.41927l.038974-4.46591c.004629-.53041.219773-1.03726.598105-1.40905l3.185434-3.13032c.378332-.37178.888859-.57805 1.419272-.57342l4.46591.03897c.530413.00463 1.037263.21978 1.409048.59811l3.130317 3.18543c.371786.37834.578052.88886.573423 1.41928l-.038973 4.46591c-.002119.24278-.048343.48062-.134133.70284l-2.040467.00003c.12047-.20926.229632-.42806.326361-.65594 1.294769-3.05029-.128358-6.57265-3.178642-7.86742-3.050285-1.29477-6.572647.12836-7.867416 3.17865-1.294769 3.05028.128357 6.57264 3.178642 7.86741 2.42733 1.03034 5.153605.33957 6.818393-1.52267l1.999676-.00067-.001975.00002 1.258051.00152c.321952-.44697.515908-.97594.557356-1.52917l.008273-.19807.038424-4.40285c.006364-.72932-.253087-1.43357-.725927-1.983l-.134208-.14591-3.086118-3.14046c-.511205-.5202-1.192647-.83473-1.9155-.88888l-.198071-.00827-4.402853-.03843c-.729318-.00636-1.433571.25309-1.983.72593l-.145908.13421-3.140456 3.08611c-.520206.51121-.834728 1.19265-.888884 1.9155l-.008274.19808-.038423 4.40285c-.006365.72932.253087 1.43357.725927 1.983l.134208.14591 3.086117 3.14045c.511205.52021 1.192647.83473 1.9155.88889l.198072.00827 4.402853.03842c.729317.00637 1.433571-.25308 1.982999-.72592l.145908-.13421 1.498934-1.47397h2.636l-3.385721 3.32834c-.567497.55767-1.333288.86707-2.128907.86013l-5.933528-.05178c-.795619-.00694-1.555894-.32966-2.113572-.89716l-4.159023-4.23225c-.557678-.5675-.867078-1.33329-.860135-2.12891l.051782-5.93353c.006943-.79562.32966-1.55589.897157-2.11357l4.232253-4.15902c.567498-.55768 1.333289-.86708 2.128908-.86014l5.933528.05179c.795619.00694 1.555894.32966 2.113572.89715l4.159023 4.23226c.557678.56749.867078 1.33328.860134 2.1289l-.051781 5.93353c-.006269.71834-.269948 1.40787-.738787 1.94549l-2.623903-.00123z"
      // fillRule="evenodd"
      transform="translate(-787 -1110)"
    />
  </svg>
)
